<template>
  <div>
    <v-text-field v-model.trim="searchInput"
      label="Search Account"
      @keyup.enter="doSearch"
      clearable @click:clear="clearSearch"
      prepend-icon="mdi-magnify" @click:prepend="doSearch"
      :rules="[ this.validInput || 'Invalid input' ]"
      hint="Search for phone, email or UID"
      single-line>
    </v-text-field>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'

export default {
  data() {
    return {
      searchInput: null,
    }
  },
  computed: {
    validPhone() {
      return !!this.searchInput &&
        this.searchInput.startsWith('+44') &&
        this.searchInput.length === 13
    },
    validEmail() {
      return !!this.searchInput && /.+@.+\..+/.test(this.searchInput)
    },
    validUid() {
      return !!this.searchInput && this.searchInput.length == 28
    },
    validInput() {
      return this.validPhone || this.validEmail || this.validUid
    }
  },
  methods: {
    processQuerySnap(snap) {
      if (snap.size === 0) {
        eventBus.$emit('appWarningSet', { color: 'warning', message: 'No account found' })
        return null
      }
      if (snap.size > 1) {
        eventBus.$emit('appWarningSet', { color: 'warning', message: `Multiple ${snap.size} accounts found` })
      }
      return { ... snap.docs[0].data(), id: snap.docs[0].id }
    },
    async doSearch() {
      eventBus.$emit('appAlterAppBar', { loading: true })
      let account = null
      try {
        // Find account
        if (this.validPhone) {
          const q = query(collection(fbfs, 'accounts'), where('phone', '==', this.searchInput))
          const snap = await getDocs(q)
          account = this.processQuerySnap(snap)
        } else if (this.validEmail) {
          const q = query(collection(fbfs, 'accounts'), where('email', '==', this.searchInput))
          const snap = await getDocs(q)
          account = this.processQuerySnap(snap)
        } else if (this.validUid) {
          const snap = await getDoc(doc(fbfs, 'accounts', this.searchInput))
          if (snap.exists()) {
            account = snap.data()
          } else {
            eventBus.$emit('appWarningSet', { color: 'warning', message: 'No account found' })
          }
        }
        // Find profile
        if (account) {
          const snap = await getDoc(doc(fbfs, 'profiles', account.id))
          if (snap.exists()) {
            account.profile = snap.data()
          } else {
            account.profile = null
            eventBus.$emit('appWarningSet', { color: 'warning', message: 'No profile found for account' })
          }
        }
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
      this.$store.commit('setAccountSelected', account)
      eventBus.$emit('appAlterAppBar', { loading: false })
    },
    clearSearch() {
    }
  }
}
</script>