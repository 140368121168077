<template>
  <v-card flat rounded="xl" class="mx-auto pt-0" max-width="360" color="rgba(255, 255, 255, 0.8)">
    <v-img src="@/assets/logo-360px.png"></v-img>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <div class="input-label">Your mobile number</div>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            ref="mobileInput"
            v-model="mobileNumber"
            @keyup.enter="sendMobile" 
            :disabled="mobileDisabled"
            :hint="mobileNumberHint"
            type="number" hide-spin-buttons
            autofocus
            :rules="[ inputRules.mobileNumberCheck ]"
            rounded outlined
            color="primary" background-color="grey lighten-4"
          >
          <template v-slot:append>
            <v-icon :disabled="sendMobileDisabled" @click="sendMobile" color="primarydark">mdi-send</v-icon>
          </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="otpShow">
      <v-row>
        <v-spacer></v-spacer>
        <div class="input-label">Your verification code</div>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            ref="otpInput"
            v-model="otpNumber"
            @keyup.enter="sendOtp"
            :disabled="otpDisabled"
            :hint="otpNumberHint"
            type="number" hide-spin-buttons
            :rules="[ inputRules.otpNumberCheck ]"
            rounded outlined
            color="primary" background-color="grey lighten-4"
            class="text-field-airy"
          >
          <template v-slot:append>
            <v-icon :disabled="sendOtpDisabled" @click="sendOtp" color="primarydark">mdi-send</v-icon>
          </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-snackbar v-model="warningShow" :color="warningColor" :timeout="6000" centered>
      {{ warningText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="warningShow = false">Close</v-btn>
      </template>
    </v-snackbar>
    <div id="recaptcha-container"></div>
  </v-card>
</template>

<script>
/*
  https://firebase.google.com/docs/auth/web/phone-auth
*/

import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { fbauth } from '@/plugins/firebase'

export default {
  data() {
    return {
      mobileNumber: '07',
      otpNumber: '',
      inputRules: {
        mobileNumberCheck: value => (value.length <= 11 && /^[0-9]+$/.test(value)) || this.mobileNumberHint,
        otpNumberCheck: value => (value.length <= 6 && /^[0-9]+$/.test(value)) || this.otpNumberHint,
      },
      mobileNumberHint: 'UK numbers only starting with 07',
      otpNumberHint: 'Six digit number received in text message',
      sendingMobile: false,
      sendingOtp: false,
      otpShow: false,
      warningText: '',
      warningColor: 'default',
      warningShow: false,
    }
  },
  computed: {
    mobileNumberValid() {
      return this.mobileNumber.length === 11 &&
              /^[0-9]+$/.test(this.mobileNumber) &&
              this.mobileNumber[0] === '0' &&
              this.mobileNumber[1] === '7'
    },
    otpNumberValid() {
      return this.otpNumber.length === 6 &&
              /^[0-9]+$/.test(this.otpNumber)
    },
    sendMobileDisabled() {
      return !this.mobileNumberValid || this.sendingMobile
    },
    sendOtpDisabled() {
      return !this.otpNumberValid || this.sendingOtp
    },
    mobileDisabled() {
      return this.sendingMobile
    },
    otpDisabled() {
      return this.sendingOtp
    }
  },
  methods: {
    createRecaptchaVerifier() {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        /* 'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
        } */
      }, fbauth)
    },
    sendMobile() {
      if (!this.mobileNumberValid) {
        // TODO: alert
        return
      }
      console.log(this.mobileNumber)
      this.sendingMobile = true

      this.createRecaptchaVerifier()
      const recaptcha = window.recaptchaVerifier

      let number = '+44' + this.mobileNumber.substring(1)

      signInWithPhoneNumber(fbauth, number, recaptcha)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message
          window.confirmationResult = confirmationResult
          this.sendingMobile = false
          this.showOtpInput()
          // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
          this.sendingMobile = false
          console.log(error)
          // TODO: Check type of error
          // FirebaseError: Firebase: Exceeded quota. (auth/quota-exceeded).
          // Error: reCAPTCHA has already been rendered in this element
          // FirebaseError: Firebase: TOO_SHORT (auth/invalid-phone-number).
          // FirebaseError: Firebase: TOO_LONG (auth/invalid-phone-number).
          // FirebaseError: Firebase: Error (auth/invalid-phone-number).
          this.warningColor = 'error'
          // this.warningText = 'INVALID PHONE NUMBER'
          this.warningText = error
          this.warningShow = true
          this.$refs.mobileInput.focus()
        })
    },
    showOtpInput() {
      this.otpShow = true
      this.$nextTick(() => {
        this.$refs.otpInput.focus();
      })
    },
    sendOtp() {
      console.log('otpNumber ', this.otpNumber)
      if (!this.otpNumberValid) {
        // TODO: alert
        return
      }
      this.sendingOtp = true

      let confirmationResult = window.confirmationResult
      confirmationResult.confirm(this.otpNumber)
        .then(() => {
          // User signed in
          this.sendingOtp = false
          this.mobileNumber='07'
          this.otpNumber=''
          this.$router.push({ name: 'dashboard' })
          // eslint-disable-next-line no-unused-vars
        }).catch((error) => {
          // User couldn't sign in
          // FirebaseError: Firebase: Error (auth/invalid-verification-code).
          // FirebaseError: Firebase: Error (auth/code-expired).
          console.log(error)
          this.sendingOtp = false
          this.warningColor = 'error'
          this.warningText = 'WRONG VERIFICATION CODE'
          this.warningShow = true
          this.$refs.otpInput.focus()
        })
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.input-label {
  text-transform: uppercase;
  /* font-weight: bold; */
  color: black;
}
.v-text-field ::v-deep(input) {
  font-size: 1.6em;
  font-weight: normal;
  letter-spacing: 2px;
  padding: 0px;
  text-align: center;
}
.text-field-airy ::v-deep(input) {
  letter-spacing: 8px;
}
.v-text-field ::v-deep(.theme--light.v-messages) {
  color: black;
}
</style>
