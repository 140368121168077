<template>
  <v-container fill-height fluid class="backdropy pa-0" :style="backdropimage">
    <v-row align="center">
      <!-- <v-spacer></v-spacer> -->
        <sign-in-form></sign-in-form>
      <!-- <v-spacer></v-spacer> -->
    </v-row>
  </v-container>
</template>

<script>
import SignInForm from '../components/SignInForm.vue'

export default {
  name: 'EntranceView',
  components: { SignInForm },
  data() {
    return {
    }
  },
  computed: {
    backdropimage() {
      const n = String(Math.floor(Math.random() * 31) + 1).padStart(2, '0')
      const url = `https://firebasestorage.googleapis.com/v0/b/map-a-muso.appspot.com/o/backdrops%2Fbd${n}.jpg?alt=media`
      return `background-image: url('${url}');`
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.backdropy {
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>