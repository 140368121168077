<template>
  <div>
    <!-- Buttons -->
    <v-btn @click="getAccounts()" disabled>
      Get Accounts
    </v-btn>
    <v-btn @click="addNotes()" disabled>
      Add Notes
    </v-btn>
    <v-btn @click="deleteNotes()" disabled>
      Delete Notes
    </v-btn>
  </div>
</template>

<script>
import { fbfs } from '@/plugins/firebase'
import { collection, getDocs, addDoc, deleteDoc, query, where } from 'firebase/firestore'

export default {
  props: {
  },
  data() {
    return {
      accounts: []
    }
  },
  computed: {
  },
  methods: {
    async getAccounts() {
      const snapshot = await getDocs(collection(fbfs, 'accounts'))
      snapshot.forEach((account) => {
        this.accounts.push(account.id)
      })
      console.log('Number of accounts', this.accounts.length)
    },
    async addNotes() {
      for (const uid of this.accounts) {
        await addDoc(collection(fbfs, 'dashboard'), {
          type: 'shortcuts',
          user: uid,
          show: true,
          order: 26,
        });
      }
    },
    async deleteNotes() {
      const snapshot = await getDocs(query(
        collection(fbfs, 'dashboard'),
        where('title', '==', 'Getting Started')
      ));

      console.log('Found', snapshot.docs.length, 'notes')

      for (const doc of snapshot.docs) {
        await deleteDoc(doc.ref)
      }
    }
  }
}
</script>