<template>
  <div>
    <tab-content>
      <v-row>
        <v-col cols="12" md="5" order-md="12">
          <account-finder></account-finder>
        </v-col>
        <v-col cols="12" md="7" order-md="1">
          <v-card flat>
            <account-card v-if="!!accountSelected" :account="accountSelected"></account-card>
            <v-card-actions v-else>
              <v-btn @click="dbGetAllSubs" color="primary">Search All</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-list three-line :key="refreshListKey">
            <template v-for="(sid, n) in subsList">
              <v-divider :key="`D${n}`"></v-divider>
              <v-list-item :key="`L${n}`">

                <!-- Icon -->
                <v-icon class="mr-4" :color="subsData[sid].show.icon.color">{{ subsData[sid].show.icon.icon }}</v-icon>

                <!-- Content -->
                <v-list-item-content>

                  <!-- Title -->
                  <v-col cols="6" sm="4" md="3">{{ sid }}</v-col>
                  <v-col cols="6">{{ subsData[sid].name }}</v-col>

                  <!-- Details -->
                  <v-col cols="12">
                    <v-list-item-subtitle>
                      {{ subsData[sid].timestamp.toDate() }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      order: {{ subsData[sid].order }}
                    </v-list-item-subtitle>
                    <!-- User -->
                    <v-list-item-subtitle v-if="subsData[sid].show.user">
                      {{ subsData[sid].show.user.name }}
                      {{ subsData[sid].show.user.phone }}
                      {{ subsData[sid].show.user.email }}
                      {{ subsData[sid].user }}
                    </v-list-item-subtitle>
                  </v-col>

                  <!-- Events -->
                  <v-col v-if="subsData[sid].show.events" offset="1">
                    <div v-for="(event, name) in subsData[sid].events" :key="name" class="subs-event-text">
                      {{ event.updated }} {{ name }}
                    </div>
                  </v-col>
                </v-list-item-content>

                <!-- Action buttons -->
                <v-list-item-action>
                  <div>
                    <v-btn small fab elevation="2"
                      @click="showUser(sid)"
                      :disabled="!hasUser(sid)"
                      color="primary--text" class="d-inline mx-3">
                      <v-icon v-if="hasUser(sid)">mdi-account</v-icon>
                      <v-icon v-else>mdi-account-off</v-icon>
                    </v-btn>
                    <v-btn small fab elevation="2"
                      @click="showEvents(sid)"
                      color="primary--text" class="d-inline mx-3">
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>

              </v-list-item>
            </template>
            <v-divider></v-divider>
          </v-list>
        </v-col>
      </v-row>
    </tab-content>
  </div>
</template>

<script>
import TabContent from '@/components/TabContent.vue'
import AccountFinder from '@/components/AccountFinder.vue'
import AccountCard from '@/components/AccountCard.vue'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, getDoc, getDocs, query, where, orderBy, limit } from 'firebase/firestore'
import { mapGetters } from 'vuex'

export default {
  name: 'SubscriptionsView',
  components: { AccountFinder, AccountCard, TabContent },
  data() {
    return {
      subsList: [],
      subsData: {},
      refreshListKey: 1,
    }
  },
  computed: {
    ...mapGetters([
      'accountSelected',
    ])
  },
  watch: {
    accountSelected() {
      this.dbGetOneSubs(this.accountSelected)
    }
  },
  methods: {
    hasUser(sid) {
      return !!this.subsData[sid].user
    },
    showEvents(sid) {
      this.subsData[sid].show.events = true
      this.refreshListKey += 1
    },
    async showUser(sid) {
      try {
        if (this.subsData[sid].user) {
          const user = {}
          const account = await getDoc(doc(fbfs, 'accounts', this.subsData[sid].user))
          if (account.exists()) {
            user.phone = account.data().phone
            user.email = account.data().email
          }
          const profile = await getDoc(doc(fbfs, 'profiles', this.subsData[sid].user))
          if (profile.exists()) {
            user.name = profile.data().name
          }
          if (user !== {}) {
            this.subsData[sid].show.user = user
            this.refreshListKey += 1
          }
        }
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
    },
    subscriptionStatus(subs) {
      let ret = 'unknown';
      if (subs.events) {
        if (Object.hasOwn(subs.events, 'cancelled') ||
          Object.hasOwn(subs.events, 'BILLING.SUBSCRIPTION.CANCELLED')) {
          ret = 'cancelled';
        } else if (Object.hasOwn(subs.events, 'activated') ||
          Object.hasOwn(subs.events, 'BILLING.SUBSCRIPTION.ACTIVATED')) {
          ret = 'activated';
        } else if (Object.hasOwn(subs.events, 'created') ||
          Object.hasOwn(subs.events, 'BILLING.SUBSCRIPTION.CREATED')) {
          ret = 'created';
        } else if (Object.hasOwn(subs.events, 'init')) {
          ret = 'init';
        }
      }
      return ret;
    },
    statusIcon(status) {
      if (status === 'init') {
        return { icon: 'mdi-arrow-right-circle-outline', color: 'primary' }
      } else if (status === 'created') {
        return { icon: 'mdi-plus-circle-outline', color: 'primary' }
      } else if (status === 'activated') {
        return { icon: 'mdi-check-circle-outline', color: 'primary' }
      } else if (status === 'cancelled') {
        return { icon: 'mdi-close-circle-outline', color: 'primary' }
      } else {
        return { icon: 'mdi-help-circle-outline', color: 'primary' }
      }
    },
    async dbGetAllSubs() {
      this.subsList = []
      this.subsData = {}
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        const snapshot = await getDocs(query(
          collection(fbfs, 'subscriptions'),
          orderBy('timestamp', 'desc'),
          limit(100)
        ))
        snapshot.forEach((doc) => {
          this.subsList.push(doc.id)
          const subs = doc.data()
          this.subsData[doc.id] = subs
          this.subsData[doc.id].status = this.subscriptionStatus(subs)
          this.subsData[doc.id].show = { icon: this.statusIcon(this.subsData[doc.id].status) }
        })
        eventBus.$emit('appWarningSet', {
          color: 'success',
          message: `Found ${snapshot.size} items`
        })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    },
    async dbGetOneSubs(account) {
      this.subsList = []
      this.subsData = {}
      if (account && account.id) {
        eventBus.$emit('appAlterAppBar', { loading: true })
        try {
          const snapshot = await getDocs(query(
            collection(fbfs, 'subscriptions'),
            where('user', '==', account.id),
            orderBy('timestamp', 'desc'),
            limit(100)
          ))
          snapshot.forEach((doc) => {
            this.subsList.push(doc.id)
            const subs = doc.data()
            this.subsData[doc.id] = subs
            this.subsData[doc.id].status = this.subscriptionStatus(subs)
            this.subsData[doc.id].show = { icon: this.statusIcon(this.subsData[doc.id].status) }
          })
          eventBus.$emit('appWarningSet', {
            color: 'success',
            message: `Found ${snapshot.size} items`
          })
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        }
        eventBus.$emit('appAlterAppBar', { loading: false })
      }
    }
  },
  async created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Subscriptions',
      buttons: [{ show: false }, { show: false }, { show: false }],
      loading: false
    })
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
.subs-event-text {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
}
.v-list-item__content > * {
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>