// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1CQIWM2ptJLn-mssBB0ncdC7Rd0vRtP0",
  authDomain: "map-a-muso.firebaseapp.com",
  projectId: "map-a-muso",
  storageBucket: "map-a-muso.appspot.com",
  messagingSenderId: "914374535289",
  appId: "1:914374535289:web:533238bba72ded4e328ca8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const fbfs = getFirestore(app);
export const fbauth = getAuth(app);
export const fbfuncs = getFunctions(app, 'europe-west2');
export const fbstorage = getStorage(app);
