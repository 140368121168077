<template>
  <div>
    <v-list-item>
      <!-- Avatar -->
      <v-list-item-avatar size="80">
        <v-img :src="avatarUrl" alt="Photo"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <!-- Name, etc -->
        <v-list-item-title>
          {{ name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-btn v-if="hasNoEmail" x-small icon
            @click="buttonClick('sendSms')"
            color="primary--text">
            <v-icon color="primary">mdi-message</v-icon>
          </v-btn>
          <v-icon v-else small color="primary">mdi-message</v-icon>
          {{ phone }}
          <!-- <v-icon small>mdi-message</v-icon> {{ phone }} -->
          <v-icon small color="primary">mdi-at</v-icon>
          {{ email }}
          <v-icon small color="primary">mdi-identifier</v-icon>
          {{ uid }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-icon small color="primary">mdi-calendar-account</v-icon>
          {{ created }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ introduction }}
        </v-list-item-subtitle>
        <!-- Skills -->
        <v-list-item-subtitle class="font-italic">
          <template v-for="s in skills">
            {{ $store.getters.skillName(s) }},
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>

      <!-- Action buttons -->
      <v-list-item-action>
        <div>

          <v-icon :color="warningColor" class="d-inline mx-2">mdi-alert-outline</v-icon>

          <v-badge
            :value="skillCount" :content="skillCount"
            offset-x="20px" offset-y="10px" color="primarylight">
            <v-icon :color="skillCount ? 'primary' : 'faded'" class="d-inline mx-2">mdi-music-clef-treble</v-icon>
          </v-badge>

          <v-badge
            :value="areaCount" :content="areaCount"
            offset-x="20px" offset-y="10px" color="primarylight">
            <v-icon :color="areaCount ? 'primary' : 'faded'" class="d-inline mx-2">mdi-map-marker-outline</v-icon>
          </v-badge>

          <v-btn v-if="hasProfile" small fab elevation="2"
            @click="buttonClick('viewProfile')"
            :color="profileIcon.color" class="white--text d-inline mx-2">
            <v-icon>{{ profileIcon.icon }}</v-icon>
          </v-btn>
          <v-icon v-else color="faded" class="d-inline mx-4">{{ profileIcon.icon }}</v-icon>

          <div class="d-flex justify-end mt-4">
            <span class="primary--text text-body-2">[{{ account.serial }}]</span>
          </div>
        </div>
      </v-list-item-action>
    </v-list-item>
    <v-divider v-if="divider"></v-divider>
  </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    divider: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    phone() {
      return this.account.phone || 'no phone'
    },
    email() {
      return this.account.email || 'no email'
    },
    uid() {
      return this.account.id || 'no uid'
    },
    created() {
      if (this.account.created) {
        return Intl.DateTimeFormat('en-gb', {
          day: 'numeric', month: 'numeric', year: 'numeric',
          hour: 'numeric', minute: 'numeric'
        }).format(this.account.created.toDate())
      } else {
        return 'no date'
      }
    },
    avatarUrl() {
      try {
        return this.$store.getters.photoUrl(
          this.account.id, this.account.profile.photos.avatar
        )
      } catch (e) {
        return null
      }
    },
    hasNoEmail() {
      return this.account.phone && !this.account.email
    },
    hasProfile() {
      return !!this.account.profile
    },
    isProfileValid() {
      if (!this.hasProfile) {
        return false
      }
      const profile = this.account.profile
      const nameValid = !!profile.name && profile.name.length >= 4 && profile.name.length <= 40
      const introValid = !!profile.introduction && profile.introduction.length >= 20 && profile.introduction.length <= 200
      const avatarValid = !!profile.photos && !!profile.photos.avatar
      return nameValid && introValid && avatarValid
    },
    isListed() {
      return !!this.skillCount && !!this.areaCount
    },
    isHalfListed() {
      return (!!this.skillCount && !this.areaCount) || (!this.skillCount && !!this.areaCount)
    },
    name() {
      try {
        return this.account.profile.name
      } catch (e) {
        return null
      }
    },
    introduction() {
      try {
        return this.account.profile.introduction
      } catch (e) {
        return null
      }
    },
    skills() {
      try {
        return this.account.profile.skills
      } catch (e) {
        return null
      }
    },
    skillCount() {
      try {
        return this.account.profile.skills.length
      } catch (e) {
        return 0
      }
    },
    areaCount() {
      return this.account.areaCount
    },
    warningColor() {
      if (!this.isProfileValid) {
        return 'amber lighten-1'
      } else if (!!this.account.subscribed && !this.isListed) {
        return 'red darken-3'
      } else if (!this.account.subscribed && this.isHalfListed) {
        return 'red lighten-3'
      } else if (!!this.account.subscribed && this.areaCount > 8) {
        return 'green lighten-2'
      } else if (!this.account.subscribed && this.areaCount > 4) {
        return 'green lighten-2'
      } else {
        return 'faded'
      }
    },
    profileIcon() {
      if (this.account.subscribed) {
        return { icon: 'mdi-account-star', color: 'primary' }
      } else {
        return { icon: 'mdi-account', color: 'primarylight' }
      }
    }
  },
  methods: {
    buttonClick(action) {
      eventBus.$emit('cardButtonClick', this.account.id, action)
    }
  }
}
</script>