const getters = {
  skillName: (state) => (code) => {
    const f = state.skills.filter(skill => {
      return skill.code === code
    })
    // TODO: Log if not exactly one found
    return f.length ? f[0].name : null
  },
  skillsFilter: (state) => (text) => {
    if (!text) return []

    const search = text.trim().toLowerCase()

    if (search.length < 3) return []

    return state.skills.filter(skill => {
      const text = skill.name.toLowerCase() + ' ' + skill.tag.toLowerCase()
      return text.indexOf(search) > -1
    })
  },
}

const state = {
  skills: [
    // STRINGS
    { code: '1110', name: 'Violin', tag: '' },
    { code: '1111', name: 'Violin (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1112', name: 'Violin (Folk)', tag: 'Fiddle' },
    { code: '1113', name: 'Violin (Electric)', tag: '' },
    { code: '1114', name: 'Violin (Pop)', tag: '' },
    { code: '1120', name: 'Viola', tag: '' },
    { code: '1121', name: 'Viola (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1122', name: 'Viola (Electric)', tag: '' },
    { code: '1123', name: 'Viola (Pop)', tag: '' },
    { code: '1124', name: 'Viola (d\'Amore)', tag: '' },
    { code: '1130', name: 'Cello', tag: '' },
    { code: '1131', name: 'Cello (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1132', name: 'Cello (Electric)', tag: '' },
    { code: '1133', name: 'Cello (Pop)', tag: '' },
    { code: '1140', name: 'Double Bass', tag: '' },
    { code: '1141', name: 'Double Bass (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1142', name: 'Double Bass (Jazz)', tag: '' },
    { code: '1143', name: 'Bass Guitar', tag: '' },
    { code: '1150', name: 'Harp', tag: '' },
    { code: '1151', name: 'Celtic Harp', tag: '' },
    // GUITARS
    { code: '1161', name: 'Classical Guitar', tag: '' },
    { code: '1162', name: 'Guitar (Flamenco)', tag: '' },
    { code: '1171', name: 'Electric Guitar (Pop, Rock)', tag: '' },
    { code: '1172', name: 'Electric Guitar (Jazz)', tag: '' },
    { code: '1181', name: 'Mandolin', tag: '' },
    { code: '1182', name: 'Mandola', tag: '' },
    { code: '1183', name: 'Mandocello', tag: '' },
    { code: '1184', name: 'Ukelele', tag: '' },
    { code: '1185', name: 'Banjo (Tenor)', tag: '' },
    { code: '1186', name: 'Banjo (5 string)', tag: '' },
    { code: '1187', name: 'Theorbo', tag: '' },
    { code: '1188', name: 'Lute', tag: '' },
    // KEYBOARDS
    { code: '1211', name: 'Keyboard', tag: '' },
    { code: '1221', name: 'Piano (Accompaniment)', tag: '' },
    { code: '1222', name: 'Piano (Solo)', tag: '' },
    { code: '1223', name: 'Piano (Chamber Music)', tag: '' },
    { code: '1224', name: 'Piano (Jazz)', tag: '' },
    { code: '1225', name: 'Harpsichord', tag: '' },
    { code: '1226', name: 'Clavichord', tag: '' },
    { code: '1227', name: 'Fortepiano', tag: '' },
    { code: '1228', name: 'Celeste', tag: '' },
    { code: '1241', name: 'Church Organ', tag: '' },
    { code: '1242', name: 'Chamber Organ', tag: '' },
    { code: '1251', name: 'Accordion', tag: '' },
    { code: '1252', name: 'Bandoneon', tag: '' },
    // WIND
    { code: '1311', name: 'Flute', tag: 'Classical' },
    { code: '1312', name: 'Flute (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1313', name: 'Flute (Jazz)', tag: '' },
    { code: '1314', name: 'Flute (Ethnic)', tag: '' },
    { code: '1315', name: 'Alto Flute', tag: '' },
    { code: '1316', name: 'Bass Flute', tag: '' },
    { code: '1321', name: 'Recorders', tag: '' },
    { code: '1322', name: 'Piccolo', tag: '' },
    { code: '1325', name: 'Oboe', tag: '' },
    { code: '1326', name: 'Oboe (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1327', name: 'Cor Anglais', tag: '' },
    { code: '1328', name: 'Oboe d\'Amore', tag: '' },
    { code: '1331', name: 'Clarinet', tag: 'Classical, Non-Period Instrument' },
    { code: '1332', name: 'Clarinet (Period Instrument)', tag: 'Classical' },
    { code: '1333', name: 'Clarinet (Jazz)', tag: '' },
    { code: '1334', name: 'Eb Clarinet', tag: '' },
    { code: '1335', name: 'Bass Clarinet', tag: '' },
    { code: '1336', name: 'Basset Horn', tag: '' },
    { code: '1341', name: 'Bassoon', tag: '' },
    { code: '1342', name: 'Bassoon (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1343', name: 'Contra Bassoon', tag: '' },
    { code: '1344', name: 'Contra Bassoon (Period Instrument)', tag: 'Baroque, Classical' },
    // BRASS
    { code: '1411', name: 'French Horn', tag: '' },
    { code: '1412', name: 'Horn (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1421', name: 'Trumpet', tag: '' },
    { code: '1422', name: 'Trumpet (Period Instrument)', tag: 'Baroque, Classical' },
    { code: '1423', name: 'Trumpet (Piccolo/D)', tag: '' },
    { code: '1424', name: 'Flugelhorn', tag: '' },
    { code: '1425', name: 'Cornet', tag: '' },
    { code: '1426', name: 'Tenor Horn', tag: '' },
    { code: '1431', name: 'Trombone', tag: 'Classical' },
    { code: '1432', name: 'Trombone (Jazz)', tag: '' },
    { code: '1433', name: 'Bass Trombone', tag: '' },
    { code: '1434', name: 'Euphonium', tag: '' },
    { code: '1435', name: 'Tuba', tag: '' },
    // SAXOPHONES
    { code: '1451', name: 'Soprano Saxophone (Classical)', tag: '' },
    { code: '1452', name: 'Soprano Saxophone (Jazz)', tag: '' },
    { code: '1453', name: 'Alto Saxophone (Classical)', tag: '' },
    { code: '1454', name: 'Alto Saxophone (Jazz)', tag: '' },
    { code: '1455', name: 'Tenor Saxophone (Classical)', tag: '' },
    { code: '1456', name: 'Tenor Saxophone (Jazz)', tag: '' },
    { code: '1457', name: 'Baritone Saxophone (Classical)', tag: '' },
    { code: '1458', name: 'Baritone Saxophone (Jazz)', tag: '' },
    { code: '1459', name: 'Bass Saxophone (Classical)', tag: '' },
    { code: '1460', name: 'Bass Saxophone (Jazz)', tag: '' },
    // PERCUSSION
    { code: '1511', name: 'Percussion (Orchestral)', tag: '' },
    { code: '1512', name: 'Percussion (Latin/Pop)', tag: '' },
    { code: '1513', name: 'Percussion (Period Instrument)', tag: 'Baroque, Classical, Early Music' },
    { code: '1514', name: 'Timpani', tag: '' },
    { code: '1515', name: 'Timpani (Period Instrument)', tag: 'Baroque, Classical, Early Music' },
    { code: '1521', name: 'Drum kit', tag: '' },
    // SINGERS
    { code: '1611', name: 'Soprano Singer (Choral)', tag: 'Classical' },
    { code: '1612', name: 'Soprano Singer (Opera)', tag: '' },
    { code: '1613', name: 'Mezzo Singer (Choral)', tag: 'Classical' },
    { code: '1614', name: 'Mezzo Soprano Singer (Opera)', tag: '' },
    { code: '1615', name: 'Alto Singer (Choral)', tag: 'Classical' },
    { code: '1616', name: 'Contralto Singer (Opera)', tag: '' },
    { code: '1617', name: 'Tenor Singer (Choral)', tag: 'Classical' },
    { code: '1618', name: 'Tenor Singer (Opera)', tag: '' },
    { code: '1619', name: 'Baritone Singer (Choral)', tag: 'Classical' },
    { code: '1620', name: 'Baritone Singer (Opera)', tag: '' },
    { code: '1621', name: 'Bass Singer (Choral)', tag: 'Classical' },
    { code: '1622', name: 'Bass Singer (Opera)', tag: '' },
    { code: '1623', name: 'Soprano Singer (Classical)', tag: 'Recital, Concert, Soloist' },
    { code: '1624', name: 'Mezzo Soprano Singer (Classical)', tag: 'Recital, Concert, Soloist' },
    { code: '1625', name: 'Contralto Singer (Classical)', tag: 'Recital, Concert, Soloist' },
    { code: '1626', name: 'Tenor Singer (Classical)', tag: 'Recital, Concert, Soloist' },
    { code: '1627', name: 'Baritone Singer (Classical)', tag: 'Recital, Concert, Soloist' },
    { code: '1628', name: 'Bass Singer (Classical)', tag: 'Recital, Concert, Soloist' },
    { code: '1631', name: 'Male Singer (Pop)', tag: 'Vocalist' },
    { code: '1632', name: 'Male Singer (Jazz)', tag: 'Vocalist' },
    { code: '1633', name: 'Male Singer (Musical Theatre)', tag: 'Vocalist' },
    { code: '1634', name: 'Male Singer (Cabaret)', tag: 'Vocalist' },
    { code: '1635', name: 'Male Singer (Folk)', tag: 'Vocalist' },
    { code: '1636', name: 'Male Singer (Other)', tag: 'Vocalist' },
    { code: '1637', name: 'Male Singer (Ethnic)', tag: 'Vocalist' },
    { code: '1641', name: 'Female Singer (Pop)', tag: 'Vocalist' },
    { code: '1642', name: 'Female Singer (Jazz)', tag: 'Vocalist' },
    { code: '1643', name: 'Female Singer (Musical Theatre)', tag: 'Vocalist' },
    { code: '1644', name: 'Female Singer (Cabaret)', tag: 'Vocalist' },
    { code: '1645', name: 'Female Singer (Folk)', tag: 'Vocalist' },
    { code: '1646', name: 'Female Singer (Other)', tag: 'Vocalist' },
    { code: '1647', name: 'Female Singer (Ethnic)', tag: 'Vocalist' },
    { code: '1651', name: 'Soprano Singer (Early Music)', tag: 'Period performance, HIP, Baroque, Renaissance' },
    { code: '1652', name: 'Mezzo Soprano Singer (Early Music)', tag: 'Period performance, HIP, Baroque, Renaissance' },
    { code: '1653', name: 'Contralto Singer (Early Music)', tag: 'Period performance, HIP, Baroque, Renaissance' },
    { code: '1654', name: 'Tenor Singer (Early Music)', tag: 'Period performance, HIP, Baroque, Renaissance' },
    { code: '1655', name: 'Baritone Singer (Early Music)', tag: 'Period performance, HIP, Baroque, Renaissance' },
    { code: '1656', name: 'Bass Singer (Early Music)', tag: 'Period performance, HIP, Baroque, Renaissance' },
    // CONDUCTORS
    { code: '1681', name: 'Conductor (Orchestral)', tag: '' },
    { code: '1682', name: 'Conductor (Choral)', tag: '' },
    { code: '1683', name: 'Conductor (Musical Theatre)', tag: '' },
    { code: '1684', name: 'Musical Director', tag: '' },
    // MISC
    { code: '1711', name: 'Arranger', tag: '' },
    { code: '1712', name: 'Orchestrator', tag: '' },
    { code: '1713', name: 'Composer', tag: '' },
    { code: '1714', name: 'Singer-Songwriter', tag: '' },
    { code: '1715', name: 'Copyist', tag: '' },
    { code: '1716', name: 'Sound Engineer', tag: '' },
    { code: '1717', name: 'Animateur', tag: 'Workshop Leader' },
    { code: '1718', name: 'Community Musician', tag: 'Education, Outreach, Workshops' },
    { code: '1719', name: 'Concert Presenter', tag: '' },
    { code: '1720', name: 'Lecturer', tag: '' },
    { code: '1721', name: 'Adjudicator', tag: '' },
    { code: '1722', name: 'Teacher', tag: '' },
    { code: '1723', name: 'Ensemble Coach', tag: '' },
    { code: '1724', name: 'Performance Coach', tag: '' },
    { code: '1725', name: 'Music Therapist', tag: '' },
    { code: '1726', name: 'Actor-Musician', tag: '' },
    { code: '1727', name: 'Musical Comedian', tag: 'Comedy' },
    // ADDITIONAL SKILLS
    { code: '1751', name: 'Sight-reading', tag: 'Notation' },
    { code: '1752', name: 'Reading Chord Symbols', tag: '' },
    { code: '1753', name: 'Improvisation', tag: '' },
    { code: '1754', name: 'Busking', tag: 'Playing Standards by Ear' },
    { code: '1755', name: 'Extended Contemporary Techniques', tag: '' },
    { code: '1756', name: 'Leader', tag: '' },
    { code: '1757', name: 'Soloist', tag: '' },
    { code: '1758', name: 'Chamber Music', tag: '' },
    { code: '1759', name: 'Session Musician', tag: '' },
    { code: '1760', name: 'Remote recording', tag: '' },
    // GENRES
    { code: '1781', name: 'Renaissance', tag: 'Early Music, HIP' },
    { code: '1782', name: 'Medieval', tag: 'Early Music, HIP' },
    // NICHE INSTRUMENTS
    { code: '1811', name: 'Strohviol', tag: '' },
    { code: '1812', name: 'Erhu', tag: '' },
    { code: '1813', name: 'Crumhorn', tag: '' },
    { code: '1814', name: 'Rebec', tag: '' },
    { code: '1815', name: 'Sackbut', tag: '' },
    { code: '1816', name: 'Shawm', tag: '' },
    { code: '1817', name: 'Viola da Gamba', tag: '' },
    { code: '1818', name: 'Viol', tag: '' },
    { code: '1819', name: 'Violone', tag: '' },
    { code: '1820', name: 'Bagpipes', tag: 'Highland Pipes' },
    { code: '1821', name: 'Whistles', tag: '' },
    { code: '1822', name: 'Pipes (Northumbrian)', tag: '' },
    { code: '1823', name: 'Pipes (Uillean)', tag: '' },
    { code: '1824', name: 'Bodhrán', tag: '' },
    { code: '1825', name: 'Keyed Fiddle', tag: '' },
    { code: '1826', name: 'Appalachian Dulcimer', tag: '' },
    { code: '1827', name: 'Hammer Dulcimer', tag: '' },
    { code: '1828', name: 'Kantele', tag: '' },
    { code: '1829', name: 'Cittern', tag: '' },
    { code: '1830', name: 'Zither', tag: '' },
    { code: '1831', name: 'Hurdy-Gurdy', tag: '' },
    { code: '1832', name: 'Theramin', tag: '' },
    { code: '1833', name: 'Musical Saw', tag: '' },
    { code: '1834', name: 'Hardanger Fiddle', tag: '' },
    { code: '1835', name: 'Pan Pipes', tag: '' },
    { code: '1836', name: 'Ocarina', tag: '' },
    { code: '1837', name: 'Heckelphone', tag: '' },
    { code: '1838', name: 'Alphorn', tag: '' },
    { code: '1839', name: 'Fanfare Trumpet', tag: '' },
    { code: '1840', name: 'Ophicleide', tag: '' },
    { code: '1841', name: 'Serpent', tag: '' },
    { code: '1842', name: 'Sousaphone', tag: '' },
    { code: '1843', name: 'Cimbalom', tag: '' },
    { code: '1844', name: 'Marimba', tag: '' },
    { code: '1845', name: 'Harmonica', tag: '' },
    { code: '1846', name: 'Harmonium', tag: '' },
    { code: '1847', name: 'Lyre', tag: '' },
    { code: '1848', name: 'Sitar', tag: '' },
    { code: '1849', name: 'Dhol', tag: '' },
    { code: '1850', name: 'Cajón', tag: 'Cahon' },
    { code: '1851', name: 'Steel Pan Drums', tag: '' },
    { code: '1852', name: 'Pipes (Period instrument)', tag: 'Hummelchen, Brugel, Gaita' },
    { code: '1853', name: 'Zampogna', tag: 'Italian pipes, bagpipes' },
    { code: '1854', name: 'Cornetto', tag: '' },
    { code: '1855', name: 'Vibraphone (Jazz)', tag: '' },
    // EQUIPMENT
    { code: '1911', name: 'Tablet', tag: 'equipment' },
    { code: '1912', name: 'Set of Pads', tag: 'equipment' },
    { code: '1921', name: 'Car', tag: 'equipment' },
    { code: '1922', name: 'Van', tag: 'equipment' },
    { code: '1931', name: 'Own PA', tag: 'equipment' },
  ]
}

export default {
  state,
  getters,
}
