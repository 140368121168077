<template>
  <div>
    <tab-content>
      <v-row>
        <v-col cols="12" md="5" order-md="12">
          <account-finder></account-finder>
        </v-col>
        <v-col cols="12" md="7" order-md="1">
          <v-card flat>
            <account-card v-if="!!accountSelected" :account="accountSelected"></account-card>
            <v-card-actions v-else>
              <v-select v-model="mode" :items="modes" label="Filter"></v-select>
              <v-btn @click="dbGetLogs" color="primary">Refresh</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-list three-line>
            <template v-for="(lid, n) in logsList">
              <v-divider :key="`D${n}`"></v-divider>
              <v-list-item :key="`L${n}`">
                <!-- Icon -->
                <v-icon class="mr-4" :color="logsData[lid].show.icon.color">
                  {{ logsData[lid].show.icon.icon }}
                </v-icon>
                <!-- Content -->
                <v-list-item-content>
                  <!-- Title -->
                  <div>
                    {{ logUserName(logsData[lid]) }}
                    <span class="text-body-2">
                      <v-icon v-if="logUserPhone(logsData[lid])" small color="primary">mdi-message</v-icon>
                      {{ logUserPhone(logsData[lid]) }}
                      <v-icon v-if="logUserEmail(logsData[lid])" small color="primary">mdi-at</v-icon>
                      {{ logUserEmail(logsData[lid]) }}
                      <v-icon small color="primary">mdi-identifier</v-icon>
                      {{ logUserId(logsData[lid]) }}
                    </span>
                  </div>
                  <div>
                    {{ logDate(logsData[lid]) }}
                  </div>
                  <div>
                    <span class="text-body-2 font-weight-light">
                      {{ logMessage(logsData[lid]) }}
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider></v-divider>
          </v-list>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-btn @click="dbGetNextPage" :disabled="nextPageDisabled" color="primary" class="mt-4">More</v-btn>
      </div>
    </tab-content>
  </div>
</template>

<script>
import TabContent from '@/components/TabContent.vue'
import AccountFinder from '@/components/AccountFinder.vue'
import AccountCard from '@/components/AccountCard.vue'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, collectionGroup, getDocs, query, where, orderBy, limit, startAfter } from 'firebase/firestore'
import { mapGetters } from 'vuex'

export default {
  name: 'LogsView',
  components: { AccountFinder, AccountCard, TabContent },
  data() {
    return {
      logsList: [],
      logsData: {},
      mode: 'All',
      modes: ['All', 'Error', 'signIn', 'createUser', 'deleteUser', 'updateEmail', 'updateSubscribed', 'createProfile', 'updateProfile', 'updateAvatar', 'createArea', 'updateArea', 'deleteArea', 'findProfiles', 'makeOffer', 'viewOffer', 'initiateSubs', 'makeSubs', 'createSubs', 'activateSubs', 'cancelSubs', 'unknownSubs'],
      paginationSize: 100,
      nextPageDisabled: true,
      lastItem: null,
    }
  },
  computed: {
    ...mapGetters([
      'accountSelected',
    ])
  },
  watch: {
    accountSelected() {
      this.dbGetOneLogs(this.accountSelected)
    }
  },
  methods: {
    logIcon(log) {
      const icon = {}
      if (log.type === 'info') {
        icon.color = 'primary'
      } else if (log.type === 'error') {
        icon.color = 'error'
      } else {
        icon.color = 'primary'
      }
      if (log.event === 'signIn') {
        icon.icon = 'mdi-login'
      } else if (log.event === 'createUser') {
        icon.icon = 'mdi-account-outline'
      } else if (log.event === 'deleteUser') {
        icon.icon = 'mdi-account-off-outline'
      } else if (log.event === 'updateEmail') {
        icon.icon = 'mdi-at'
      } else if (log.event === 'updateSubscribed') {
        if (log.subscribed) {
          icon.icon = 'mdi-account-star-outline'
        } else {
          icon.icon = 'mdi-account-remove-outline'
        }
      } else if (log.event === 'createProfile') {
        icon.icon = 'mdi-account-plus-outline'
      } else if (log.event === 'updateProfile') {
        icon.icon = 'mdi-account-sync-outline'
      } else if (log.event === 'updateAvatar') {
        icon.icon = 'mdi-account-badge-outline'
      } else if (log.event === 'createArea') {
        icon.icon = 'mdi-map-marker-plus-outline'
      } else if (log.event === 'updateArea') {
        icon.icon = 'mdi-map-marker-radius-outline'
      } else if (log.event === 'deleteArea') {
        icon.icon = 'mdi-map-marker-remove-outline'
      } else if (log.event === 'findProfiles') {
        icon.icon = 'mdi-magnify'
      } else if (log.event === 'makeOffer') {
        icon.icon = 'mdi-hand-extended-outline'
      } else if (log.event === 'viewOffer') {
        icon.icon = 'mdi-handshake-outline'
      } else if (log.event === 'initiateSubs' || log.event === 'makeSubs' ||
        log.event === 'createSubs' || log.event === 'activateSubs') {
        icon.icon = 'mdi-cash-plus'
      } else if (log.event === 'cancelSubs') {
        icon.icon = 'mdi-cash-remove'
      } else if (log.event === 'unknownSubs') {
        icon.icon = 'mdi-cash-sync'
      } else {
        icon.icon = 'mdi-help-outline'
      }
      return icon
    },
    logTitle(log) {
      if (this.mode === 'one') {
        return log.event
      } else {
        return this.$store.getters.accountEmail(log.user) + ' ' + log.user
      }
    },
    logUserName(log) {
      return this.$store.getters.accountName(log.user)
    },
    logUserPhone(log) {
      return this.$store.getters.accountPhone(log.user)
    },
    logUserEmail(log) {
      return this.$store.getters.accountEmail(log.user)
    },
    logUserId(log) {
      return log.user
    },
    logDate(log) {
      return Intl.DateTimeFormat('en-gb', {
          day: 'numeric', month: 'numeric', year: 'numeric',
          hour: 'numeric', minute: 'numeric', second: 'numeric'
        }).format(log.timestamp.toDate())
    },
    logMessage(log) {
      if (log.event === 'signIn') {
        return 'fev: ' + log.fev
      } else if (log.event === 'createArea' || log.event === 'updateArea' || log.event === 'deleteArea') {
        return log.name
      } else if (log.event === 'updateProfile') {
        return `name: ${log.name}, intro: ${log.introduction}`
      } else if (log.event === 'updateSubscribed') {
        return `subscribed: ${log.subscribed}`
      } else if (log.event === 'findProfiles') {
        return `skill: ${this.$store.getters.skillName(log.skill)}, gig: ${log.gig}, counts: ${log.countLocal}, ${log.countExtended}, ${log.countNotViewable},`
      } else if (log.event === 'deleteUser') {
        return `phone: ${log.phone}, email: ${log.email}`
      } else {
        return ''
      }
    },
    async dbGetNextPage() {
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        // Filters
        const constraints = []
        if (this.mode === 'All') {
          // No constraint
        } else if (this.mode === 'Error') {
          constraints.push(where('type', '==', 'error'))
        } else {
          constraints.push(where('event', '==', this.mode))
        }

        // 2nd, 3rd, ... pages
        if (this.lastItem) {
          constraints.push(startAfter(this.lastItem))
        }

        const snapshot = await getDocs(query(
          collectionGroup(fbfs, 'logsof'),
          orderBy('timestamp', 'desc'),
          ...constraints,
          limit(this.paginationSize),
        ))

        this.lastItem = snapshot.docs[snapshot.docs.length - 1]

        if (snapshot.size < this.paginationSize) {
          this.nextPageDisabled = true
        } else {
          this.nextPageDisabled = false
        }

        snapshot.forEach((doc) => {
          this.logsList.push(doc.id)
          const log = doc.data()
          this.logsData[doc.id] = log
          this.logsData[doc.id].show = { icon: this.logIcon(log) }
        })

        eventBus.$emit('appWarningSet', {
          color: 'success',
          message: `Found ${snapshot.size} items`
        })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    },
    async dbGetLogs() {
      this.logsList = []
      this.logsData = {}
      this.lastItem = null
      this.dbGetNextPage()
    },
    async dbGetOneLogs(account) {
      this.mode = 'one'
      this.logsList = []
      this.logsData = {}
      if (account && account.id) {
        eventBus.$emit('appAlterAppBar', { loading: true })
        try {
          const snapshot = await getDocs(query(
            collection(fbfs, `logs/${account.id}/logsof`),
            orderBy('timestamp', 'desc'),
            limit(100)
          ))
          snapshot.forEach((doc) => {
            this.logsList.push(doc.id)
            const log = doc.data()
            this.logsData[doc.id] = log
            this.logsData[doc.id].show = { icon: this.logIcon(log) }
          })
          eventBus.$emit('appWarningSet', {
            color: 'success',
            message: `Found ${snapshot.size} items`
          })
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        }
        eventBus.$emit('appAlterAppBar', { loading: false })
      }
    }
  },
  async created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Logs',
      buttons: [{ show: false }, { show: false }, { show: false }],
      loading: false
    })
  },
  beforeDestroy() {
  }
}
</script>
