<template>
  <div>
    <v-tabs-items v-model="tabName">

      <v-tab-item value="dbtiles">
        <tab-title>
          Tiles
        </tab-title>
        <tab-content>
          <tiles-admin></tiles-admin>
        </tab-content>
      </v-tab-item>

      <v-tab-item value="dbnotes">
        <tab-title>
          Dashboard Notes
        </tab-title>
        <tab-content>
          <notes-admin></notes-admin>
        </tab-content>
      </v-tab-item>

      <v-tab-item value="dbpromos">
        <tab-title>
          Share Codes
        </tab-title>
        <tab-content>
          <promos-admin></promos-admin>
        </tab-content>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import TilesAdmin from '@/components/TilesAdmin.vue'
import NotesAdmin from '@/components/NotesAdmin.vue'
import PromosAdmin from '@/components/PromosAdmin.vue'
import { eventBus } from '@/main'

export default {
  name: 'DatabaseView',
  components: { TilesAdmin, NotesAdmin, PromosAdmin, TabTitle, TabContent },
  data() {
    return {
    }
  },
  computed: {
    tabName: {
      get() {
        return this.$store.state.tabs.tabName
      },
      set(value) {
        this.$store.commit('setTabName', value)
      }
    },
  },
  methods: {
  },
  async created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Database',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: false
    })
    eventBus.$emit('appTabsSet', [
      { href: 'dbtiles', icon: 'mdi-grid' },
      { href: 'dbnotes', icon: 'mdi-view-dashboard' },
      { href: 'dbpromos', icon: 'mdi-tag' },
    ])
    this.tabName = 'dbtiles'
  }
}
</script>
