<template>
  <tab-content>
    Accounts: {{ accountCount }}
    Profiles: {{ profileCount }}
    Viewable: {{ viewableCount }}
    Subscribed: {{ subscribedCount }}
  </tab-content>
</template>

<script>
import TabContent from '@/components/TabContent.vue'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, getCountFromServer, query, where } from 'firebase/firestore'

export default {
  name: 'DashboardView',
  components: { TabContent },
  data() {
    return {
      accountCount: null,
      profileCount: null,
      viewableCount: null,
      subscribedCount: null,
    }
  },
  computed: {
  },
  methods: {
  },
  async created() {
    eventBus.$emit('appTabsSet', null)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Dashboard',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: false
    })
    try {
      // Counts
      let count
      count = await getCountFromServer(collection(fbfs, 'accounts'))
      this.accountCount = count.data().count
      count = await getCountFromServer(collection(fbfs, 'profiles'))
      this.profileCount = count.data().count
      count = await getCountFromServer(query(collection(fbfs, 'profiles'), where('viewable', '==', true)))
      this.viewableCount = count.data().count
      count = await getCountFromServer(query(collection(fbfs, 'accounts'), where('subscribed', '==', true)))
      this.subscribedCount = count.data().count
    } catch (e) {
      eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
    }
  }
}
</script>
