import Vue from 'vue'
import Vuex from 'vuex'

import accounts from './accounts'
import skills from './skills'
import tabs from './tabs'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    accounts,
    skills,
    tabs
  },
  state: {
    user: null,
  },
  getters: {
    frontendVersion() {
      return '0.30'
    },
    photoUrl: () => (id, photo) => {
      return photo ?
        `https://firebasestorage.googleapis.com/v0/b/map-a-muso.appspot.com/o/profiles%2F${id}%2F${photo}?alt=media` :
        'https://firebasestorage.googleapis.com/v0/b/map-a-muso.appspot.com/o/profiles%2Fdefault%2Favatar.jpg?alt=media&token=af757ab1-ff44-45bc-850c-76fb60e6dc75'
    }
  },
  mutations: {
    // User
    setUser(state, payload) {
      state.user = payload
      console.log('User:', state.user)
    },
    // All
    resetAll(state) {
      state.user = null
    }
  }
})

export default store


import { onAuthStateChanged } from 'firebase/auth'
import { fbauth } from '@/plugins/firebase'

onAuthStateChanged(fbauth, (user) => {
  store.commit('setUser', user)

  // If logged out
  if (!user) {
    store.commit('resetAll')
  }
})
