<template>
  <div>
    <v-tabs-items v-model="tabName">

      <v-tab-item value="proflist">
        <tab-title>
          Accounts
        </tab-title>
        <tab-content>
          <v-card flat>
            <v-list three-line :key="refreshProfilesKey">
              <account-card
                v-for="id in accountsList"
                :account="accountsData[id]" :key="id"
                divider>
              </account-card>
            </v-list>
          </v-card>
          <div class="text-center">
            <v-btn @click="dbGetNextPage" :disabled="nextPageDisabled" color="primary" class="mt-4">More</v-btn>
          </div>
          <div class="text-right">
            <a @click="downloadEmails()" :href="downloadEmailsUrl" :download="downloadEmailsFilename">
              Download emails
            </a>
          </div>
        </tab-content>
      </v-tab-item>

      <v-tab-item value="profview">
        <tab-title>
          Profile Details
        </tab-title>
        <tab-content>
          <profile-viewer v-if="currentProfile" :id="currentProfile"></profile-viewer>
        </tab-content>
      </v-tab-item>

    </v-tabs-items>

    <v-dialog v-model="showSmsDialog" max-width="600px">
      <v-card>
        <v-card-title>
          Send SMS
        </v-card-title>
        <v-card-text>
          To: {{ smsTo }} <br>
          Text: {{ smsText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="smsTo=null;showSmsDialog=false" color="primarylight onprimarylight--text" class="mx-2">
            Cancel
          </v-btn>
          <v-btn @click="dbSendSms()" color="primarylight onprimarylight--text" class="mx-2">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import ProfileViewer from '@/components/ProfileViewer.vue'
import TabTitle from '@/components/TabTitle.vue'
import TabContent from '@/components/TabContent.vue'
import AccountCard from '@/components/AccountCard.vue'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, addDoc, getDoc, getDocs, getCountFromServer, query, orderBy, limit, startAfter } from 'firebase/firestore'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfilesView',
  components: { ProfileViewer, AccountCard, TabTitle, TabContent },
  data() {
    return {
      paginationSize: 100,
      nextPageDisabled: false,
      currentProfile: null,
      downloadEmailsUrl: null,
      downloadEmailsFilename: null,
      smsTo: null,
      smsText: 'Hi, thanks for your interest in Map a Muso! Our new Profile Wizard makes it quick and easy to complete your profile. Try it now at https://app.mapamuso.com',
      showSmsDialog: false,
      refreshProfilesKey: 1
    }
  },
  computed: {
    tabName: {
      get() {
        return this.$store.state.tabs.tabName
      },
      set(value) {
        this.$store.commit('setTabName', value)
      }
    },
    ...mapGetters([
      'accountEmail',
      'accountFirstName',
      'accountViewable',
      'accountsList',
      'accountsData',
      'accountPaginationMark',
      'accountPaginationCounter',
      'accountPaginationSerial'
    ])
  },
  watch: {
    tabName() {
      if (this.tabName === 'proflist') {
        this.currentProfile = null
      }
    },
    accountPaginationCounter() {
      if (this.accountPaginationCounter === 0) {
        this.refreshProfilesKey += 1
        eventBus.$emit('appAlterAppBar', { loading: false })
        console.log('Mark count', this.$store.getters.markCount())
      }
    }
  },
  methods: {
    async dbGetNextPage() {
      try {
        eventBus.$emit('appAlterAppBar', { loading: true })

        const accountsColl = collection(fbfs, 'accounts')

        let snapshot = null
        if (this.accountPaginationMark) {
          // Next page
          snapshot = await getDocs(query(
            accountsColl,
            orderBy('created', 'desc'),
            startAfter(this.accountPaginationMark),
            limit(this.paginationSize)
          ))
        } else {
          // Count entries for serial number
          const count = await getCountFromServer(accountsColl)
          this.$store.commit('setAccountPaginationSerial', count.data().count)
          // First page
          snapshot = await getDocs(query(
            accountsColl,
            orderBy('created', 'desc'),
            limit(this.paginationSize)
          ))
        }

        this.$store.commit('setAccountPaginationCounter', snapshot.size)
        this.$store.commit('setAccountPaginationMark', snapshot.docs[snapshot.docs.length - 1])

        if (this.accountPaginationCounter < this.paginationSize) {
          this.nextPageDisabled = true
        }

        snapshot.forEach(async (account) => {

          // Update account
          const uid = account.id
          this.$store.commit('mergeAccount', {
            id: uid,
            data: { ... account.data(), id: uid, serial: this.accountPaginationSerial }
          })
          this.$store.commit('decrementAccountPaginationSerial')

          // Get profile details
          const profile = await getDoc(doc(fbfs, 'profiles', uid))
          if (profile.exists) {
            // Update profile details
            this.$store.commit('mergeAccount', {
              id: uid, data: { profile: profile.data() }
            })
            // Count areas
            const count = await getCountFromServer(collection(fbfs, `areas/${uid}/areasof`))
            this.$store.commit('mergeAccount', {
              id: uid, data: { areaCount: count.data().count }
            })
          }
          this.$store.commit('decrementAccountPaginationCounter')
        })
        eventBus.$emit('appWarningSet', {
          color: 'success',
          message: `Found ${snapshot.size} items`
        })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        eventBus.$emit('appAlterAppBar', { loading: false })
      }
    },
    async dbSendSms() {
      try {
        const docRef = await addDoc(collection(fbfs, 'messages_twilio'), {
          from: 'Map a Muso',
          to: this.smsTo,
          body: this.smsText,
          type: 'support'
        })
        console.log('Sent SMS', docRef.id, this.smsTo, this.smsText)
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
      this.showSmsDialog = false
    },
    async handleCardButtonClick(uid, action) {
      if (action === 'viewProfile') {
        this.currentProfile = uid
        this.tabName = 'profview'
      } else if (action === 'sendSms') {
        this.smsTo = this.accountsData[uid].phone
        this.showSmsDialog = true
      }
    },
    downloadEmails() {
      let emails = ''
      let countOk = 0
      let countNok = 0
      this.accountsList.forEach((uid) => {
        const email = this.accountEmail(uid)
        if (email && this.accountViewable(uid)) {
          const name = this.accountFirstName(uid) || ''
          emails += email + ',' + name + '%0A'
          countOk += 1
        } else {
          countNok += 1
        }
      })
      eventBus.$emit('appWarningSet', {
        color: 'info',
        message: `Saved ${countOk} emails, found ${countNok} empty`
      })
      this.downloadEmailsUrl = 'data:text/plain;charset=utf-8,' + emails
      this.downloadEmailsFilename = 'emails.txt'
    }
  },
  async created() {
    eventBus.$on('cardButtonClick', this.handleCardButtonClick)
    eventBus.$emit('appAlterAppBar', {
      alter: false,
      title: 'Profiles',
      buttons: [ { show: false }, { show: false }, { show: false } ],
      loading: false
    })
    eventBus.$emit('appTabsSet', [
      { href: 'proflist', icon: 'mdi-account-multiple' },
      { href: 'profview', icon: 'mdi-card-account-details' },
    ])
    this.tabName = 'profinfo'

    if (!this.accountsList.length) {
      this.dbGetNextPage()
    }
  },
  beforeDestroy() {
    eventBus.$off('cardButtonClick', this.handleCardButtonClick)
  }
}
</script>
