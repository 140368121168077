<template>
  <div>
    <div v-if="!!profile">
      <v-row>
        <v-col cols="8">
          <p class="text-h5">{{ profile.name }}</p>
          <p class="text-subtitle-2 font-italic">
            <span v-for="s in profile.skills" :key="s">
              {{ $store.getters.skillName(s) }},
            </span>
          </p>
          <p class="text-subtitle-1">{{ profile.introduction }}</p>
        </v-col>
        <v-col cols="4">
          <v-avatar size="120">
            <img :src="$store.getters.photoUrl(id, avatarPhoto)" alt="Photo" />
          </v-avatar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" grow slider-color="secondary">
            <v-tab href="#biog">Biog</v-tab>
            <v-tab v-if="profile.photos" href="#photos">Photos</v-tab>
            <v-tab href="#areas">Areas</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Resume -->
            <v-tab-item value="biog">
              <v-card flat tile color="white" class="mt-4 pa-4">
                <div v-html="profile.resume" id="resume"></div>
              </v-card>
            </v-tab-item>

            <!-- Photos -->
            <v-tab-item v-if="profile.photos" value="photos">
              <v-card flat tile class="pa-4">
                <v-carousel v-model="featureIndex" height="auto">
                  <v-carousel-item v-for="(feature, n) in featurePhotos" :key="n"
                    :src="$store.getters.photoUrl(id, feature)">
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-tab-item>

            <!-- Areas -->
            <v-tab-item value="areas">
              <v-card flat tile class="mt-4 pa-4">
                <div v-for="(area, id) in areas" :key="id">
                  <v-btn icon
                    @click="viewArea(area)"
                    color="primary--text">
                    <v-icon color="primary">mdi-map-marker-radius</v-icon>
                  </v-btn>
                  {{ area.name }}
                  ({{ rangeName(area.range) }})
                </div>
                <v-card v-if="areas.length>0" max-width="800px" class="mt-4">
                  <area-card :areas="areas" :size="800" maponly>
                  </area-card>
                </v-card>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="showAreaDialog" max-width="600px">
      <area-card :areas="showAreas">
        <template v-slot:actions>
          <v-btn @click="showAreaDialog=false"
            color="primarylight onprimarylight--text">
            Close
          </v-btn>
        </template>
      </area-card>
    </v-dialog>
  </div>
</template>

<script>
import AreaCard from '@/components/AreaCard.vue'
import { RANGES } from '@/utils/llmesh'
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'

export default {
  components: { AreaCard },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      profile: null,
      avatarPhoto: null,
      featurePhotos: [],
      areas: [],
      tab: null,
      featureIndex: 0,
      showAreas: [],
      showAreaDialog: false
    }
  },
  computed: {
  },
  watch: {
    id() {
      this.dbGetProfile()
    },
    showAreaDialog() {
      if (!this.showAreaDialog) {
        this.showAreas = []
      }
    }
  },
  methods: {
    async dbGetProfile() {
      if (this.id) {
        eventBus.$emit('appAlterAppBar', { loading: true })
        try {
          // Load profile data
          const profileSnap = await getDoc(doc(fbfs, 'profiles', this.id))
          if (!profileSnap.exists()) {
            throw 'Can\'t find profile'
          }
          // Update profile data
          this.profile = profileSnap.data()
          if (!this.profile.skills) {
            this.profile.skills = []
          }
          this.refreshPhotos(this.profile.photos)
          // Get areas
          this.areas = []
          const areaSnap = await getDocs(collection(fbfs, `areas/${this.id}/areasof`))
          areaSnap.forEach((doc) => {
            this.areas.push({ ... doc.data(), id: doc.id })
          })
        } catch (e) {
          eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        }
        eventBus.$emit('appAlterAppBar', { loading: false })
      }
    },
    refreshPhotos(photos) {
      this.avatarPhoto = null
      this.featurePhotos = []
      if (photos) {
        Object.keys(photos).sort().forEach((key) => {
          if (key === 'avatar') {
            this.avatarPhoto = photos.avatar
          }
          if (key.startsWith('feature') && photos[key]) {
            this.featurePhotos.push(photos[key])
          }
        })
      }
    },
    viewArea(area) {
      this.showAreas = [ area ]
      this.showAreaDialog = true
    },
    rangeName(range) {
      return RANGES[range].name
    }
  },
  async created() {
    this.dbGetProfile()
  },
  beforeDestroy() {
  }
}
</script>

<style scoped>
#resume ::v-deep(h1) {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
  font-family: "Roboto", sans-serif;
  margin-top: 24px;
  margin-bottom: 8px;
}
#resume ::v-deep(h2) {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  margin-bottom: 8px;
}
#resume ::v-deep(p) {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0.009375em;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  margin-bottom: 8px;
}
</style>