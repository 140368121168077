import Vue from 'vue'
import VueRouter from 'vue-router'
import AppShellView from '@/views/AppShellView.vue'
import DashboardView from '@/views/DashboardView.vue'
import ProfilesView from '@/views/ProfilesView.vue'
import LogsView from '@/views/LogsView.vue'
import SubscriptionsView from '@/views/SubscriptionsView.vue'
import DatabaseView from '@/views/DatabaseView.vue'
import EntranceView from '@/views/EntranceView.vue'
import { fbauth } from '@/plugins/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/welcome',
    name: 'entrance',
    component: EntranceView,
    beforeEnter: (to, from, next) => {
      if (fbauth.currentUser) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    },
  },
  {
    path: '',
    component: AppShellView,
    beforeEnter: (to, from, next) => {
      if (fbauth.currentUser) {
        next()
      } else {
        next({ name: 'entrance' })
      }
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: '/profiles',
        name: 'profiles',
        component: ProfilesView,
      },
      {
        path: '/logs',
        name: 'logs',
        component: LogsView,
      },
      {
        path: '/subscriptions',
        name: 'subscriptions',
        component: SubscriptionsView,
      },
      {
        path: '/database',
        name: 'database',
        component: DatabaseView,
      },
      {
        path: '*',
        redirect: '/dashboard'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
