<template>
  <v-card flat tile>
    <div class="tab-title-text">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
}
</script>

<style scoped>
div.tab-title-text {
  padding: 12px 16px 4px 16px;
  text-transform: uppercase;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 2rem;
  word-break: break-all;
}
</style>
