import { render, staticRenderFns } from "./AppShellView.vue?vue&type=template&id=6ed7285c&scoped=true&"
import script from "./AppShellView.vue?vue&type=script&lang=js&"
export * from "./AppShellView.vue?vue&type=script&lang=js&"
import style0 from "./AppShellView.vue?vue&type=style&index=0&id=6ed7285c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed7285c",
  null
  
)

export default component.exports