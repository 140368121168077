<template>
  <div>
    <!-- Buttons -->
    <v-btn @click="createCodes" disabled>
      Create
    </v-btn>
    <v-btn @click="disableCodes" disabled>
      Disable
    </v-btn>
    <v-btn @click="downloadCodes" :href="downloadCodesUrl" :download="downloadCodesFilename">
      Download
    </v-btn>
    <v-list flat :key="refreshAccountsKey">
      <div v-for="id in accountsList" :key="id">
        <v-list-item v-if="accountViewable(id)" three-line>
          <v-list-item-content>
            <v-list-item-title>
              {{ accountName(id) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-icon small color="primary">mdi-calendar-account</v-icon>
              {{ accountCreated(id) }}
              <v-icon small color="primary">mdi-message</v-icon>
              {{ accountPhone(id) }}
              <v-icon small color="primary">mdi-at</v-icon>
              {{ accountEmail(id) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="accountsData[id].sharecode">
              <v-icon small color="primary">mdi-tag</v-icon>
              {{ accountsData[id].sharecode }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfs } from '@/plugins/firebase'
import { collection, doc, getDocs, setDoc, updateDoc, query, orderBy, where } from 'firebase/firestore'
import { mapGetters } from 'vuex'

export default {
  props: {
  },
  data() {
    return {
      accounts: [],
      refreshAccountsKey: 1,
      downloadCodesUrl: null,
      downloadCodesFilename: null,
    }
  },
  computed: {
    ...mapGetters([
      'accountPhone',
      'accountEmail',
      'accountName',
      'accountFirstName',
      'accountCreated',
      'accountViewable',
      'accountsList',
      'accountsData',
    ])
  },
  methods: {
    makeId(length) {
      let result = ''
      const abc = 'ABCDEFGHKLMNPRSTUVWXYZ'
      for (let i=0; i < length; i++) {
        result += abc.charAt(Math.floor(Math.random() * abc.length))
      }
      return result
    },
    hasSharecode(uid) {
      return !!this.accountsData[uid].sharecode
    },
    async getSharecodes() {
      const snapshot = await getDocs(query(
        collection(fbfs, 'promos'),
        orderBy('owner')
      ))
      snapshot.forEach((code) => {
        this.$store.commit('mergeAccount', {
          id: code.data().owner, data: { sharecode: code.id }
        })
        this.refreshAccountsKey += 1
      })
    },
    async createCodes() {
      let count = 0
      for (const uid of this.accountsList) {
        if (!this.accountViewable(uid) || this.hasSharecode(uid)) {
          continue
        }
        const id = this.makeId(6)
        try {
          await setDoc(doc(fbfs, 'promos', id), {
            owner: uid,
            valid: true,
            planset: 'ref01',
            campaign: 'referral01'
          })
          console.log('New sharecode', id, 'for', this.accountName(uid))
          count += 1
        } catch (e) {
          console.log('Error: ' + e)
        }
      }
      eventBus.$emit('appWarningSet', {
        color: 'info',
        message: `Created ${count} sharecodes`
      })
      await this.getSharecodes()
    },
    async disableCodes() {
      eventBus.$emit('appAlterAppBar', { loading: true })
      try {
        const snapshot = await getDocs(query(
          collection(fbfs, 'promos'),
          where('campaign', '==', 'referral01')
        ))
        snapshot.forEach( async (promo) => {
          await updateDoc(doc(fbfs, 'promos', promo.id), { valid: false })
        })
        eventBus.$emit('appWarningSet', {
          color: 'success',
          message: `Updated ${snapshot.size} items`
        })
      } catch (e) {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
      }
      eventBus.$emit('appAlterAppBar', { loading: false })
    },
    async downloadCodes() {
      let list = ''
      let count = 0
      this.accountsList.forEach((id) => {
        if (this.hasSharecode(id)) {
          list += this.accountEmail(id) + ', '
          list += this.accountFirstName(id) + ', '
          list += this.accountsData[id].sharecode + '%0A'
          count += 1
        }
      })
      eventBus.$emit('appWarningSet', {
        color: 'info',
        message: `Saved ${count} sharecodes`
      })
      this.downloadCodesUrl = 'data:text/plain;charset=utf-8,' + list
      this.downloadCodesFilename = 'sharecodes.txt'
    }
  },
  async created() {
    this.getSharecodes()
  }
}
</script>