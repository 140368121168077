const getters = {
  accountSelected(state) {
    return state.accountSelected
  },
  accountPhone: (state) => (uid) => {
    try {
      return state.accountsData[uid].phone
    } catch (e) {
      return null
    }
  },
  accountEmail: (state) => (uid) => {
    try {
      return state.accountsData[uid].email
    } catch (e) {
      return null
    }
  },
  accountName: (state) => (uid) => {
    try {
      return state.accountsData[uid].profile.name
    } catch (e) {
      return null
    }
  },
  accountFirstName: (state) => (uid) => {
    try {
      let name = state.accountsData[uid].profile.name
      let n = ''
      if (name) {
        n = name.split(' ', 1)[0]
      }
      return n
    } catch (e) {
      return null
    }
  },
  accountCreated: (state) => (uid) => {
    try {
      return Intl.DateTimeFormat('en-gb', {
        day: 'numeric', month: 'numeric', year: 'numeric',
        hour: 'numeric', minute: 'numeric'
      }).format(state.accountsData[uid].created.toDate())
    } catch (e) {
      return null
    }
  },
  accountViewable: (state) => (uid) => {
    try {
      return state.accountsData[uid].profile.viewable
    } catch (e) {
      return false
    }
  },
  markCount: (state) => () => {
    let count = 0
    for (const uid in state.accountsData) {
      if (Object.hasOwn(state.accountsData, uid)) {
        if (state.accountsData[uid].profile && state.accountsData[uid].profile.skills) {
          count += 25 * state.accountsData[uid].areaCount * state.accountsData[uid].profile.skills.length
        }
      }
    }
    return count
  },
  accountsList(state) {
    return state.accountsList
  },
  accountsData(state) {
    return state.accountsData
  },
  accountPaginationMark(state) {
    return state.accountPaginationMark
  },
  accountPaginationCounter(state) {
    return state.accountPaginationCounter
  },
  accountPaginationSerial(state) {
    return state.accountPaginationSerial
  }
}

const mutations = {
  setAccountSelected(state, payload) {
    state.accountSelected = payload
  },
  mergeAccount(state, payload) {
    if (Object.hasOwn(state.accountsData, payload.id)) {
      Object.assign(state.accountsData[payload.id], payload.data)
    } else {
      state.accountsList.push(payload.id)
      state.accountsData[payload.id] = payload.data
    }
  },
  deleteAccounts(state) {
    state.accountsList = []
    state.accountsData = {}
  },
  setAccountPaginationMark(state, payload) {
    state.accountPaginationMark = payload
  },
  setAccountPaginationCounter(state, payload) {
    state.accountPaginationCounter = payload
  },
  decrementAccountPaginationCounter(state) {
    state.accountPaginationCounter -= 1
  },
  setAccountPaginationSerial(state, payload) {
    state.accountPaginationSerial = payload
  },
  decrementAccountPaginationSerial(state) {
    state.accountPaginationSerial -= 1
  }
}

const state = {
  accountSelected: null,
  accountsList: [],
  accountsData: {},
  accountPaginationMark: null,
  accountPaginationCounter: null,
  accountPaginationSerial: null,
}

export default {
  state,
  getters,
  mutations
}
