<template>
  <div>
    <!-- Buttons -->
    <v-btn @click="crosscheckTiles()">
      Crosscheck Tiles
    </v-btn>
    <v-btn @click="generateTiles()" disabled>
      Generate Tiles
    </v-btn>
    <!-- Crosscheck tiles result -->
    <div v-if="crosscheckTilesResult" :key="refreshResultCounter">
      <div>
        Profiles require {{ crosscheckTilesResult.profilesMarkCount }} marks<br>
        Tiles have {{ crosscheckTilesResult.tilesMarkCount }} marks
      </div>
      Marks required but missing
      <ul>
        <li v-for="(value, name) in crosscheckTilesResult.unmarked" :key="name">
          {{ name }}
        </li>
      </ul>
      Marks present but not required
      <ul>
        <li v-for="(value, name) in crosscheckTilesResult.marked" :key="name">
          {{ name }}
        </li>
      </ul>
    </div>
    <!-- Generate tiles result -->
    <div v-if="generateTilesResult" :key="refreshResultCounter">
      <div>
        Generated {{ generateTilesResult.markCount }} marks
        on {{ generateTilesResult.tileCount }} tiles
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import { fbfuncs } from '@/plugins/firebase'
import { httpsCallable } from 'firebase/functions'

export default {
  props: {
  },
  data() {
    return {
      tileCount: null,
      crosscheckTilesResult: null,
      generateTilesResult: null,
      refreshResultCounter: 1,
    }
  },
  computed: {
  },
  methods: {
    resetResults() {
      this.crosscheckTilesResult = null
      this.generateTilesResult = null
    },
    crosscheckTiles() {
      this.resetResults()
      eventBus.$emit('appAlterAppBar', { loading: true })
      const cct = httpsCallable(fbfuncs, 'crosscheckTiles')
      cct()
      .then((result) => {
        this.crosscheckTilesResult = result.data
        this.refreshCrosscheckResultCounter += 1
        eventBus.$emit('appAlterAppBar', { loading: false })
      })
      .catch((e) => {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        eventBus.$emit('appAlterAppBar', { loading: false })
      })
    },
    generateTiles() {
      this.resetResults()
      eventBus.$emit('appAlterAppBar', { loading: true })
      const gt = httpsCallable(fbfuncs, 'generateTiles')
      gt()
      .then((result) => {
        this.generateTilesResult = result.data
        this.refreshResultCounter += 1
        eventBus.$emit('appAlterAppBar', { loading: false })
      })
      .catch((e) => {
        eventBus.$emit('appWarningSet', { message: 'Error: ' + e })
        eventBus.$emit('appAlterAppBar', { loading: false })
      })
    }
  }
}
</script>