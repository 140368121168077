<template>
  <v-card>
    <v-card-title v-if="!maponly">
      <div v-for="(area, id) in areas" :key="id">
        {{ area.name }}
      </div>
    </v-card-title>
    <v-card-text>
      <v-img aspect-ratio="1" :src="mapUrl"></v-img>
    </v-card-text>
    <v-card-actions v-if="!maponly" class="pb-4">
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import { areaCornersLatlng, RANGES } from '@/utils/llmesh'

export default {
  props: {
    areas: {
      type: Array,
      required: true
    },
    maponly: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 320
    }
  },
  data() {
    return {
      apiKey: 'AIzaSyDNnD3uOFdgO1z9MiZ5ZyD5DciQ0xYp8ag'
    }
  },
  computed: {
    mapUrl() {
      let p = ''
      for (const area of this.areas) {
        p += 'path=fillcolor:0xFF000033|weight:0'
        for (const c of areaCornersLatlng(RANGES[area.range].tiles, area.mesh)) {
          p += '|' + c.lat + ',' + c.lng
        }
        p += '&'
      }
      p += `size=${this.size}x${this.size}&scale=2`
      return `https://maps.googleapis.com/maps/api/staticmap?${p}&key=${this.apiKey}`
    }
  },
  methods: {
  }
}
</script>